import Vue from 'vue'
import moment from 'moment'

Vue.filter('title', function (value, replacer = '_') {
  if (!value) return ''
  value = value.toString()

  const arr = value.split(replacer)
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('dateFormatter', function (value) {
  return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
})

Vue.filter('currencyFormatter', (value) => {
  if (!value) return 0
  if (typeof value === 'string' && value.includes(',')) value = value.replaceAll(',', '')

  const twoDecimals = parseFloat(value).toFixed(2)

  if (isNaN(twoDecimals)) return 'Número invalido'
  
  const formatPrice = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(twoDecimals)
  return formatPrice
})