import axios from '../../../axios/index.js'

import appConfig from '@/../appConfig.js'

// Token Refresh
const subscribers = []

function addSubscriber (callback) {
  subscribers.push(callback)
}

export default {
  init () {
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      const { config, response } = error
      const originalRequest = config

      if (response && response.status === 401) {
        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = `Bearer ${access_token}`
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      }
      return Promise.reject(error)
    })
  },
  login (login, password, remember, two_factor_code) {
    return axios.post(`${appConfig.apiUrl}/login`, {
      login,
      password,
      remember,
      two_factor_code
    })
  },
  registerUser (name, email, pwd) {
    return axios.post(`${appConfig.apiUrl}/register`, {
      name,
      email,
      password: pwd
    })
  },
  refreshToken () {
    return axios.post(`${appConfig.apiUrl}/refresh-token`, {accessToken: localStorage.getItem('accessToken')})
  },
  parseJwt (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const parsedload = decodeURIComponent(atob(base64).split('').map((c) => {
      return `%${(`00${  c.charCodeAt(0).toString(16)}`).slice(-2)}`
    }).join(''))
    return JSON.parse(parsedload)
  },
  isAuthenticated () {
    // Check if jwt token exist and if is not expired
    return !!localStorage.getItem('accessToken') && (Date.now() < this.parseJwt(localStorage.getItem('accessToken')).exp * 1000)
  }
}
