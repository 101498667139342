/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from '@/http/requests/auth/jwt/index.js'
import router from '@/router'

export default {
  loginJWT ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.login(payload.login, payload.password, payload.remember, payload.code2FA)
        .then(response => {
          // If two Factor Authentication is required
          if (response && response.status === 206) {
            reject({
              status: response.status,
              message: 'Se requiere verificación de dos pasos'
            })
          }

          // If there's user data in response
          if (response.data.user && (response.data.user.roles.includes('super-admin') || response.data.user.roles.includes('super-accountant') || response.data.user.roles.includes('super-moderator-agency') || response.data.user.roles.includes('super-moderator-media-company'))) {
            // Set accessToken
            localStorage.setItem('accessToken', response.data.token)

            // Update user details
            commit('UPDATE_USER_INFO', response.data.user, {root: true})

            // Set bearer token in axios
            commit('SET_BEARER', response.data.token)

            // Navigate User to homepage
            router.push(router.currentRoute.query.to || '/')

            resolve(response)
          } else {
            reject({message: 'No tienes permiso para acceder al panel'})
          }

        })
        .catch(error => {
          if (!!error.response && !!error.response.status && error.response.status === 400) {
            reject({message: 'Datos de acceso incorrectos'})
          }
          if (!!error.response && !!error.response.status && error.response.status === 422) {
            reject({message: 'El código es incorrecto, intenta de nuevo'})
          }
          reject(error)
        })
    })
  },
  fetchAccessToken () {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => { resolve(response) })
    })
  }
}
