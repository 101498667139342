/*=========================================================================================
File Name: router.js
Description: Routes for vue-router. Lazy loading is enabled.
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import jwt from '@/http/requests/auth/jwt/index.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'campaigns',
          component: () => import('./views/Campaigns.vue'),
          meta: {
            authRequired: true,
            checkPermission: 'list_panel_statistic_campaigns'
          }
        },
        {
          path: '/billing',
          name: 'billing',
          component: () => import('./views/Billing.vue'),
          meta: {
            authRequired: true,
            checkPermission: 'list_panel_statistic_campaigns'
          }
        },
        {
          path: '/charges',
          name: 'charges',
          component: () => import('./views/Charge.vue'),
          meta: {
            authRequired: true,
            checkPermission: 'list_panel_statistic_campaigns'
          }
        },
        {
          path: '/stats',
          name: 'stats',
          component: () => import('./views/Stats.vue'),
          meta: {
            authRequired: true,
            checkPermission: 'show_statistic_kpi'
            // onlyView: ['super-admin']
          }
        },
        {
          path: '/screens',
          name: 'screens',
          component: () => import('./views/Screens.vue'),
          meta: {
            authRequired: true
          }
        },
        {
          path: '/licenses',
          name: 'licenses',
          component: () => import('./views/Licenses.vue'),
          meta: {
            authRequired: true,
            checkPermission: 'show_licenses'
          }
        },
        {
          path: '/contable',
          name: 'contable',
          component: () => import('./views/Contable.vue'),
          meta: {
            authRequired: true,
            checkPermission: 'show_licenses'
          }
        },
        {
          path: '/goals',
          name: 'goals',
          component: () => import('./views/Goals.vue'),
          meta: {
            authRequired: true,
            checkPermission: 'show_licenses'
          }
        },
        {
          path: '/page2',
          name: 'page-2',
          component: () => import('./views/Page2.vue')
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/no-frame-goals',
          name: 'no-frame-goals',
          component: () => import('@/views/PublicGoals.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  // If auth required, check login. If login fails redirect to login page

  if (to.meta.authRequired) {
    if (!jwt.isAuthenticated()) {
      router.push({ path: '/login', query: { to: to.path } })
      // Specify the current path as the customState parameter, meaning it
      // will be returned to the application after auth
    } else {
      const user = JSON.parse(localStorage.getItem('userInfo'))

      // Check permissions
      if (to.meta.checkPermission && to.meta.checkPermission !== '') {
        if (user.permissions.includes(to.meta.checkPermission)) {
          // TODO: Change for permissions
          if (to.meta.onlyView && to.meta.onlyView !== '' && !user.roles.some(ai => to.meta.onlyView.includes(ai))) {
            if (user.roles.includes('super-moderator-media-company')) {
              next()
            } else {
              next({ name: 'campaigns'})
            }
          } else {
            next()
          }
        } else if (user.permissions.includes('list_panel_statistic_campaigns')) { // TODO: if don't have permission redirect to comun page
          next({ name: 'campaigns'})
        } else if (user.permissions.includes('show_statistic_kpi')) {
          next({ name: 'stats'})
        } else {
          next({ name: 'page-login'})
        }
      } else {
        next()
      }
    }
  }
  return next()
})

export default router
